// import packages
import { useNavigate } from 'react-router-dom';

// import component
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

// import icons
import { Arrow } from '../assets/chev/index.jsx';

export const Glossary = () => {
	const navigate = useNavigate();

	return (
		<>
			<Navbar />
			<div className="sm:grid sm:grid-cols-4 mx-auto px-4 sm:px-0">
				<div></div>
				{/* <div className="text-luif-text mx-auto px-0 sm:px-0 my-8 space-y-4"> */}
				<div className="text-luif-text my-4 space-y-4 col-span-2 bg-zinc-900 rounded-xl py-4 sm:px-8 px-8 drop-shadow-xl">
					<p className="text-[32px] logo font-black tracking-tighter">Glossary</p>
					<p className="text-[20px] subText font-light tracking-widest">
						At LVLUPINFINANCE, we break down financial terms, investing jargon, and complex financial documents into simple, easy-to-understand explanations. We help
						you grasp what these terms mean, why they matter, and how they’re used, making it easier to navigate the financial world confidently.
					</p>

					<hr className="opacity-50 w-full" />

					<div className="grid grid-cols-1">
						<div className="grid grid-cols-2">
							<div className="flex justify-start">
								<p className="logo font-black tracking-wider text-[20px] hover:text-luif-cta-2">Form 4</p>
							</div>
							<div className="flex justify-end">
								<div className="h-8 w-8 fill-luif-text hover:fill-luif-cta-2 cursor-pointer" onClick={() => navigate('/glossary/form-4', { replace: true })}>
									<Arrow />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div></div>
			</div>
			<Footer />
		</>
	);
};
