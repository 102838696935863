// import packages
import { useState } from 'react';

// import components
import { HamburgerIcon } from '../icons/hamburger.jsx';

export const Navbar = () => {
	const [toggle, setToggle] = useState(false);

	const renderMenu = () => {
		if (toggle) {
			return (
				<>
					<div onClick={() => setToggle(!toggle)} className="absolute top-0 left-0 bg-tipt-dark-bg h-screen w-screen z-10 opacity-60"></div>
					<div className="absolute top-0 right-0 bg-luif-bg-2 w-5/6 h-screen z-20">
						<div className="relative top-8">
							<div className="mx-auto px-4">
								<div className="grid-cols-1 text-center space-y-4">
									<div className="hover:text-green-400 px-4 py-4 bg-zinc-900 w-full rounded-xl">
										<a className="logo font-black text-[20px]" href="https://square.link/u/tFtalKxd">
											Support Us
										</a>
									</div>

									<div className="hover:text-green-400 px-4 py-4 bg-zinc-900 w-full rounded-xl">
										<a className="logo font-black text-[20px]" href="#content">
											Our Content
										</a>
									</div>

									<div className="hover:text-green-400 px-4 py-4 bg-zinc-900 w-full rounded-xl">
										<a className="logo font-black text-[20px]" href="#about-us">
											About Us
										</a>
									</div>

									<div className="hover:text-green-400 px-4 py-4 bg-zinc-900 w-full rounded-xl">
										<a className="logo font-black text-[20px]" href="/glossary">
											Glossary
										</a>
									</div>
								</div>
								{/* <hr className="opacity-20" /> */}
							</div>
						</div>

						<div className="flex justify-center">
							<button
								onClick={() => setToggle(!toggle)}
								className="absolute bottom-12 rounded-xl bg-luif-cta-2 text-zinc-800 px-4 py-2 w-5/6 logo font-black text-[20px] uppercase">
								Close
							</button>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div onClick={() => setToggle(!toggle)} className="md:hidden pr-4 flex justify-center items-center">
						<button className="text-green-400">
							<HamburgerIcon />
						</button>
					</div>
				</>
			);
		}
	};

	return (
		<nav className="bg-gray-900 text-white py-4">
			<div className="container mx-auto flex justify-between items-center">
				{/* Left: Logo or Brand */}
				<a href="/" className="text-[20px] logo pl-4 font-black tracking-tighter hover:tracking-wide">
					LVLUPINFINANCE
				</a>

				{/* Right: Navigation Links */}
				<div className="hidden md:flex space-x-6">
					<a href="https://square.link/u/tFtalKxd" className="hover:text-green-400">
						Support Us
					</a>
					<a href="/#content" className="hover:text-green-400">
						Our Content
					</a>
					<a href="/#about-us" className="hover:text-green-400">
						About Us
					</a>
					<a href="/glossary" className="hover:text-green-400">
						Glossary
					</a>
				</div>

				{/* Mobile Menu */}
				{renderMenu()}
			</div>
		</nav>
	);
};
