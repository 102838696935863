// import packahes
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// import components
import { App } from './app.js';
import { Form4 } from './components/glossary/form_4.jsx';

// import pages
import { Tos } from './pages/tos.jsx';
import { Privacy } from './pages/pp.jsx';
import { Glossary } from './pages/glossary.jsx';

const container = document.getElementById('app');
const root = createRoot(container);

// inject react router
const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
	},
	{
		path: '/terms-of-service',
		element: <Tos />,
	},
	{
		path: '/privacy-policy',
		element: <Privacy />,
	},
	{
		path: '/glossary',
		element: <Glossary />,
	},
	{
		path: '/glossary/form-4',
		element: <Form4 />,
	},
	{
		path: '*',
		element: <App />,
	},
]);

root.render(
	<div className="bg-luif-bg h-dvh w-dvw overflow-scroll">
		<RouterProvider router={router} />
	</div>
);
