// import images
import palantir from '../../assets/pltr-v2.png';
import serve from '../../assets/serv-v2.png';
import cava from '../../assets/cava.jpg';
import mu from '../../assets/mu-v2.png';

export const Landing = () => {
	return (
		<div className="text-center mx-auto px-8 sm:px-60">
			<div className="my-12">
				<div className="">
					<div className="">
						<h1 className="text-[32px] text-luif-cta-2 mainText uppercase font-bold">Join Our Community of Smart Investors!</h1>
						<div className="my-4"></div>
						<p className="text-[24px] text-luif-text mainText font-extralight">
							Support bite-sized, actionable financial insights that help you make better investment decisions every day.
						</p>
						<div className="my-4"></div>
						<p className="text-[24px] text-luif-text subText font-bold">
							Your donation helps us create more in-depth company analysis, tutorials, and financial tips, so you can make smarter investment decisions. Support us
							today!
						</p>
					</div>

					<div className="my-12 tipButton">
						<a href="https://square.link/u/tFtalKxd" className="rounded-xl bg-luif-cta-2 text-luif-bg px-8 py-4 text-[24px] mainText font-bold">
							Support Us!
						</a>
					</div>
				</div>

				{/* <hr className="opacity-15" /> */}

				<div className="text-center text-luif-text py-4">
					<p className="text-[24px] mainText">Join a Thriving Investment Community!</p>

					<div className="grid grid-cols-1 sm:grid-cols-3 space-y-4 sm:space-y-0 sm:space-x-4 my-4 text-center">
						<div className="bg-zinc-900 py-8 rounded-xl w-full px-8">
							<h2 className="text-[24px] text-luif-cta-2 logo font-black">100+ Followers</h2>
							<p className="subText text-thin text-luif-text text-[16px]">Our community is rapidly growing—be a part of it!</p>
						</div>
						<div className="bg-zinc-900 py-8 rounded-xl w-full px-8">
							<h2 className="text-[24px] text-luif-cta-2 logo font-black">60,000+ Views</h2>
							<p className="subText text-thin text-luif-text text-[16px]">Thousands of people trust our content to make smarter investment decisions.</p>
						</div>
						<div className="bg-zinc-900 py-8 rounded-xl w-full px-8">
							<h2 className="text-[24px] text-luif-cta-2 logo font-black">1,000+ Likes</h2>
							<p className="subText text-thin text-luif-text text-[16px]">People are loving the insights we provide—join the conversation!</p>
						</div>
					</div>
				</div>

				<hr className="opacity-15" />

				<div className="my-2 py-4" id="content">
					<h3 className="text-[24px] text-luif-text mainText font-bold">Get a Sneak Peek:</h3>
					<h3 className="text-[24px] text-luif-text mainText font-bold">Our Most Popular Content</h3>
					<div className="grid grid-cols-2 sm:grid-cols-4 text-center text-luif-text justify-center py-4">
						<div className="rounded-xl w-6/6 h-5/6 justify-self-center my-2 px-2">
							<div className="border-2 border-zinc-900 rounded-xl hoverLinks">
								<a href="https://www.tiktok.com/@lvlupinfinance/video/7378916116480953643?is_from_webapp=1&sender_device=pc&web_id=7397928512477152814">
									<img src={palantir} alt="image of palantir technologies" className="rounded-t-xl" />
									<div className="bg-zinc-900 rounded-b-xl py-4 px-4">
										<p className="text-[8px] sm:text-[12px] subText font-light">Learn about Palantir Technologies business model. 👔🤝</p>
									</div>
								</a>
							</div>
						</div>
						<div className="rounded-xl w-6/6 h-5/6 justify-self-center my-2 px-2">
							<div className="border-2 border-zinc-900 rounded-xl hoverLinks">
								<a href="https://www.tiktok.com/@lvlupinfinance/video/7394446506343796014?is_from_webapp=1&sender_device=pc&web_id=7397928512477152814">
									<img src={serve} alt="image of serve robotics" className="rounded-t-xl" />
									<div className="bg-zinc-900 rounded-b-xl py-4 px-4">
										<p className="text-[8px] sm:text-[12px] subText font-light">Interested in Serve Robotics? 🤖 Learn about what $SERV is.</p>
									</div>
								</a>
							</div>
						</div>
						<div className="rounded-xl w-6/6 h-5/6 justify-self-center my-2 px-2">
							<div className="border-2 border-zinc-900 rounded-xl hoverLinks">
								<a href="https://www.tiktok.com/@lvlupinfinance/video/7415446981352492334?is_from_webapp=1&sender_device=pc&web_id=7397928512477152814">
									<img src={cava} alt="image of palantir technologies" className="rounded-t-xl" />
									<div className="bg-zinc-900 rounded-b-xl py-4 px-4">
										<p className="text-[8px] sm:text-[12px] subText font-light">Interested in $CAVA? 🤔 Learn about what CAVA is in this video! 📈</p>
									</div>
								</a>
							</div>
						</div>
						<div className="rounded-xl w-6/6 h-5/6 justify-self-center my-2 px-2">
							<div className="border-2 border-zinc-900 rounded-xl hoverLinks">
								<a href="https://www.tiktok.com/@lvlupinfinance/video/7386690782343793963?is_from_webapp=1&sender_device=pc&web_id=7397928512477152814">
									<img src={mu} alt="image of micron" className="rounded-t-xl" />
									<div className="bg-zinc-900 rounded-b-xl py-4 px-4">
										<p className="text-[8px] sm:text-[12px] subText font-light">$MU - Learn about Micron’s Business Model 👔🤝</p>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>

				<hr className="opacity-15" />

				<div className="text-center text-luif-text my-2 py-4">
					<p className="text-[20px] mainText font-bold">Love What You See? Help Us Continue to Provide Free, High-Quality Financial Content by Donating Today.</p>
					<div className="my-8 tipButton">
						<a href="https://square.link/u/tFtalKxd" className="rounded-xl bg-luif-cta-2 text-luif-bg px-8 py-4 text-[24px] mainText font-bold">
							Support Us!
						</a>
					</div>
				</div>

				<hr className="opacity-15" />

				<div className="my-4">
					<div className="text-center text-zinc-900 mx-auto px-4 sm:px-12 my-8 py-12 space-y-4 bg-zinc-900 shadow-lg rounded-lg" id="about-us">
						<p className="text-[24px] logo font-bold text-luif-cta-2">About Us</p>
						<p className="text-[20px] subText font-light text-luif-text">
							Welcome to LVLUPINFINANCE! Our goal is to help you make better financial decisions by simplifying the often confusing world of investing. We understand
							how overwhelming it can be to navigate complex financial reports and jargon, and that’s why we've created this platform—to break down financial
							information in a way that’s clear, actionable, and easy to understand. We started this journey to help everyday people feel more confident with their
							investments. Whether you're just starting out or looking to sharpen your financial skills, you’ll find content here that empowers you to make informed
							decisions.
						</p>
					</div>

					<div className="text-center text-zinc-900 mx-auto px-4 sm:px-12 my-8 py-12 space-y-4 bg-zinc-900 shadow-lg rounded-lg">
						<p className="text-[24px] logo font-bold text-luif-cta-2">Our Mission and Transparency</p>
						<p className="text-[20px] subText font-light text-luif-text">
							At LVLUPINFINANCE, We use reliable sources such as SEC filings, including 10-Q and 10-K reports, to break down the complex data. These filings contain
							essential information about a company's financial health and future outlook, but we simplify the details, helping you make smarter financial decisions.
							Our content transforms technical reports into easy-to-understand insights, so you don’t have to spend hours combing through financial jargon.
						</p>
					</div>

					{/* <div className="text-center text-zinc-900 mx-auto px-4 sm:px-12 my-8 py-12 space-y-4 bg-zinc-900 shadow-lg rounded-lg">
						<p className="text-[24px] logo font-bold text-luif-cta-2">Why This Matters</p>
						<p className="text-[20px] subText font-light text-luif-text">
							Understanding what’s happening behind the scenes with a company can dramatically improve your investment strategy. But many people avoid financial
							filings because they seem complex and confusing. By offering clear, concise interpretations of these documents, We aim to empower you with the knowledge
							to navigate the stock market confidently.
						</p>
					</div> */}

					<div className="text-center text-zinc-900 mx-auto px-4 sm:px-12 my-8 py-12 space-y-4 bg-zinc-900 shadow-lg rounded-lg">
						<p className="text-[24px] logo font-bold text-luif-cta-2">How You Can Support Our Mission</p>
						<p className="text-[20px] subText font-light text-luif-text">
							Providing free financial education is our passion, but it also takes a lot of time and resources. If you’ve found our content valuable, consider making
							a donation to support the platform. Your contributions help us create more content, improve the quality of our research, and keep this resource free and
							accessible for everyone. Together, we can make financial literacy something that’s within reach for all.
						</p>
					</div>
				</div>

				<div className="my-8 tipButton">
					<a href="https://square.link/u/tFtalKxd" className="rounded-xl bg-luif-cta-2 text-luif-bg px-8 py-4 text-[24px] mainText font-bold">
						Support Us!
					</a>
				</div>
			</div>
		</div>
	);
};
